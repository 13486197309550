$(document).ready(initPage);

function initPage() {
  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }

  checkScroll();

  $(window).scroll(function () {
    checkScroll();
  });

  $('.toggler').click(function () {
    $('body').toggleClass('is-mobile-menu');
  });

  const heroSwiper = new Swiper('.swiper--home-hero', {
    slidesPerView: '1',
    effect: 'fade',
    fade: { crossFade: true },
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    pagination: {
      el: '.swiper--home-hero .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
  const brandsSwiper = new Swiper('.swiper--brands', {
    slidesPerView: '7',
    speed: 600,
    loop: true,
    spaceBetween: 20,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    navigation: {
      nextEl: '.section-brands .swiper-next',
      prevEl: '.section-brands .swiper-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
      1400: {
        slidesPerView: 6,
      },
      1600: {
        slidesPerView: 7,
      },
    },
  });
  const caseStudiesSwiper = new Swiper('.swiper--case-studies', {
    slidesPerView: '3',
    speed: 600,
    loop: true,
    spaceBetween: 50,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    navigation: {
      nextEl: '.section-case-study .swiper-next',
      prevEl: '.section-case-study .swiper-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  });
}
